import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
// import "swiper/swiper-bundle.min.css";
import "./SliderSection.scss";
import "swiper/css";
import { collection, getDocs, query, limit } from "firebase/firestore";
import { db } from "../../firebase"; // Make sure to import your Firebase configuration
import { Autoplay, Pagination, Navigation } from "swiper/modules";

export default function SliderSection() {
  var [products, setProducts] = useState([]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const productsCollection = collection(db, "products");
        const productsQuery = query(productsCollection, limit(5)); // Limit the query to 5 products
        const productsSnapshot = await getDocs(productsQuery);
        const productsList = productsSnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        setProducts(productsList); // Save products to state
      } catch (error) {
        console.error("Error fetching products: ", error);
      }
    };

    fetchProducts();
  }, []);

  return (
    <>
      <section className="sliderContainer">
        <Swiper
          className="swiper"
          spaceBetween={0}
          autoplay={{
            delay: 2500,
            disableOnInteraction: true,
          }}
          slidesPerView={3}
          centeredSlides={true}
          onSlideChange={() => console.log("slide change")}
          onSwiper={(swiper) => console.log(swiper)}
          grabCursor={true}
          modules={[Autoplay, Pagination, Navigation]}
          breakpoints={{
            // When window width is >= 320px
            320: {
              slidesPerView: 1, // 1 slide for mobile devices
              centeredSlides: false,
            },
            // When window width is >= 640px
            640: {
              slidesPerView: 2, // 2 slides for small tablets
              centeredSlides: false,
            },
            // When window width is >= 1024px
            1024: {
              slidesPerView: 3, // 3 slides for desktops
              centeredSlides: true,
            },
          }}
        >
          {products.map((product) => (
            <SwiperSlide key={product.id} className="swiperSlide">
              <a href={`/product/${product.id}`}>
                <div className="swiperImage">
                  <img src={product.mainImage} alt="" />
                </div>
                <div className="swiperText">
                  <span>
                    <b>{product.name}</b>
                    <br />
                    <span>{product.tagline}</span>
                  </span>
                </div>
              </a>
            </SwiperSlide>
          ))}
        </Swiper>

        <a className="transparentButton" href="products">
          Explore Products
        </a>
      </section>
    </>
  );
}
