import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { Box, Typography, Divider, Paper, CircularProgress, Button, Grid2 } from '@mui/material';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { getShiprocketOrderStatus } from '../../services/shiprocket_services'; // Assuming you have the service setup
import 'react-toastify/dist/ReactToastify.css';
import './orderDetail.scss';

export default function OrderDetail() {
  const { orderId } = useParams();
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  // Fetch order details and check ShipRocket status
  useEffect(() => {
    const fetchOrderDetails = async () => {
      setLoading(true);
      try {
        const orderRef = doc(db, 'orders', orderId);
        const orderSnap = await getDoc(orderRef);
        if (orderSnap.exists()) {
          const orderData = orderSnap.data();
          setOrder(orderData);

          // Fetch status from ShipRocket
          if (orderData.shiprocket_order_id) {
            const shiprocketStatus = await getShiprocketOrderStatus(orderData.shiprocket_order_id);
           console.log('shiprocketOrder :',shiprocketStatus.data )
            // Update order status in Firestore if different
            if (shiprocketStatus && shiprocketStatus.data.status !== orderData.orderStatus) {
              await updateDoc(orderRef, { orderStatus: shiprocketStatus.data.status });
              setOrder((prev) => ({ ...prev, orderStatus: shiprocketStatus.data.status }));
              console.log('Order Status Updated')
            }
          }
        } else {
          toast.error('Order not found.');
        }
      } catch (error) {
        console.error('Error fetching order details:', error);
        toast.error('Failed to fetch order details.');
      }
      setLoading(false);
    };

    fetchOrderDetails();
  }, [orderId]);

  const handleProductClick = (productId) => {
    navigate(`/product/${productId}`);
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (!order) {
    return <Typography variant="h6">Order not found.</Typography>;
  }

  return (
    <div className="order-detail-container">
      <Typography variant="h4" align="center" className="order-title">
        Order Details
      </Typography>

      {/* Order Information */}
      <Paper elevation={3} className="order-detail-card">
        <Typography variant="h6" className="section-title">
          Order Information
        </Typography>
        <Divider className="divider" />
        <Typography variant="body1">
          <strong>Order ID:</strong> {orderId}
        </Typography>
        <Typography variant="body1">
          <strong>Order Status:</strong> {order.orderStatus}
        </Typography>
        <Typography variant="body1">
          <strong>Payment Status:</strong> {order.paymentStatus}
        </Typography>
        <Typography variant="body1">
          <strong>Payment Method:</strong> {order.paymentMethod}
        </Typography>
        <Typography variant="body1">
          <strong>Total Amount:</strong> ₹{order.totalPrice}
        </Typography>
        <Typography variant="body1">
          <strong>Transaction Time:</strong>{' '}
          {order.transactionTime?.toDate().toLocaleString()}
        </Typography>
        {order.trackingNumber && (
          <Typography variant="body1">
            <strong>Tracking Number:</strong> {order.trackingNumber}
          </Typography>
        )}
        <Typography variant="body1">
          <strong>Shipping Method:</strong> {order.shippingMethod}
        </Typography>
      </Paper>

      {/* Products List */}
      <Paper elevation={3} className="order-detail-card">
        <Typography variant="h6" className="section-title">
          Products
        </Typography>
        <Divider className="divider" />
        <Grid2 container spacing={2}>
          {order.items.map((product, index) => (
            <Grid2 item xs={12} key={index} className="product-detail">
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                className="product-item"
              >
                <Box className="product-info">
                  <Typography variant="body1">
                    <strong>{product.name}</strong>
                  </Typography>
                  <Typography variant="body2">Quantity: {product.quantity}</Typography>
                  <Typography variant="body2">Service Time: {product.serviceTime}</Typography>
                  <Typography variant="body2">Price: ₹{product.discountedPrice}</Typography>
                 
                  {product?.serviceTime == 3 ? (<Typography variant="body2">Service Total: ₹{200 * product.quantity}</Typography>):(<Typography variant="body2">Service Total: ₹0</Typography>)}
                  {product?.serviceTime == 3? ( <Typography variant="body2">
                    Total: ₹{(product.discountedPrice * product.quantity) + (200 * product.quantity)}
                  </Typography>): (<Typography variant="body2">Total: ₹{product.discountedPrice * product.quantity}</Typography>)}
                
                </Box>
                <div
                  className="product-image"
                  onClick={() => handleProductClick(product.productId)}
                >
                  <img
                    src={product.mainImage}
                    alt={product.name}
                    className="product-img"
                  />
                </div>
              </Box>
              <Divider className="divider" />
            </Grid2>
          ))}
        </Grid2>
      </Paper>

      {/* Shipping Address */}
      <Paper elevation={3} className="order-detail-card">
        <Typography variant="h6" className="section-title">
          Shipping Address
        </Typography>
        <Divider className="divider" />
        <Typography variant="body1">
          <strong>Name:</strong> {order.shippingAddress?.name}
        </Typography>
        <Typography variant="body1">
          <strong>Street:</strong> {order.shippingAddress?.street}
        </Typography>
        {order.shippingAddress?.street_2 && (
          <Typography variant="body1">
            <strong>Street 2:</strong> {order.shippingAddress?.street_2}
          </Typography>
        )}
        <Typography variant="body1">
          <strong>Pin Code:</strong> {order.shippingAddress?.zipCode}
        </Typography>
        <Typography variant="body1">
          <strong>Phone:</strong> {order.shippingAddress?.phone}
        </Typography>
        <Typography variant="body1">
          <strong>City:</strong> {order.shippingAddress?.city}
        </Typography>
        <Typography variant="body1">
          <strong>State:</strong> {order.shippingAddress?.state}
        </Typography>
      </Paper>
    </div>
  );
}
