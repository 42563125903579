import React, { useState, useEffect } from "react";
import "./Navbar.scss"; // Link to your updated CSS file
import TrakidooLogo from "../../Assets/TrakidooLogo.png";
import ShoppingCart from "../../Assets/Shoppin-cart.png";
import profile from "../../Assets/profile-user.png";
import { auth } from "../../firebase"; // Firebase authentication
import { signOut } from "firebase/auth"; // Import signOut function
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchCart, selectCart } from "../../Redux/features/CartSlice";
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../firebase'; // Make sure to import your Firebase configuration

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [scrolling, setScrolling] = useState(false);
  const [user, setUser] = useState(null);
  var [products, setProducts] = useState([]);
  var [kidsProducts, setKidsProducts] = useState([]);
  var [vehicleProducts, setVehicleProducts] = useState([]);
  var [personalproducts, setPersonalProducts] = useState([]);
  var [petProducts, setPetProducts] = useState([]);
  const { items, totalQuantity } = useSelector(selectCart);

  const dispatch = useDispatch();
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
    setDropdownOpen(false);
  };

  const removeNavLinksActive = () => {
    setMenuOpen(false);
    setDropdownOpen(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolling(true);
        // setDropdownOpen(false);
        setMenuOpen(false);
      } else {
        setScrolling(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);


  useEffect(() => {

    const fetchProducts = async () => {
      try {
        const productsCollection = collection(db, 'products');
        const productsSnapshot = await getDocs(productsCollection);
        const productsList = productsSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
        setProducts(productsList);  // Save products to state

        setKidsProducts(productsList.filter(product => product.productType === "kids").slice(0, 4));
        setVehicleProducts(productsList.filter(product => product.productType === "vehicle").slice(0, 4));
        setPersonalProducts(productsList.filter(product => product.productType === "personaltracker").slice(0, 4));
        setPetProducts(productsList.filter(product => product.productType === "pet").slice(0, 4));

      } catch (error) {
        console.error('Error fetching products: ', error);
      }
    };

    fetchProducts();
  }, []);


  // Monitor Firebase authentication state
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user); // User is logged in
        dispatch(fetchCart(user.uid));
      } else {
        setUser(null); // User is logged out
      }
    });
    return () => unsubscribe(); // Cleanup subscription on component unmount
  }, [dispatch]);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      setUser(null); // Reset the user state on logout
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  const activateDropdown = () => {
    setDropdownOpen(!dropdownOpen);
    setMenuOpen(false);
  };

  return (
    <header className="navBox">
      <nav className={`nav ${scrolling ? "nav-scrolled" : ""}`} id="nav">
        {/* Logo */}
        <div className="navLogoBox">
          <a className="navLogo" href="/">
            <img src={TrakidooLogo} alt="logo" />
          </a>
        </div>

        

        {/* Buttons and Icons */}
        <div className="button-icons">
          {/* Navigation Links */}
        <div className={`nav-links ${menuOpen ? "nav-links-active" : ""}`}>
          <ul>
            <li className="productsDropdown">
              <Link onClick={activateDropdown}>Products <img src={require("../../Assets/download.png")} alt="" /></Link>
            </li>
            <li>
              <Link to="/products" onClick={removeNavLinksActive}>
                Store
              </Link>
            </li>
          </ul>
        </div>

          {user ? (
            // If user is logged in, show profile menu
            <div className="profile-menu">
              <div className="dropdown">
                <button className="dropbtn">
                  <img src={profile} alt="user" />

                  <i className="fa fa-caret-down"></i>
                </button>
                <div className="dropdown-content">
                  {user?.displayName && <p>{user?.displayName}</p>}
                  <Link to="/profile" onClick={removeNavLinksActive}>My Profile</Link>
                  <Link to="/order" onClick={removeNavLinksActive}>Orders</Link>
                  <a href="/login" onClick={handleLogout}>
                    Logout
                  </a>
                </div>
              </div>
            </div>
          ) : (
            // If user is not logged in, show login/signup options
            <div className="auth-menu">
              <a href="/login" className="auth-btn">
                Login
              </a>
              {/* <a href="/sign-up" className="auth-btn">Sign Up</a> */}
            </div>
          )}
          <Link to="/cart" className="cart-link" onClick={removeNavLinksActive}>
            <div className="cart-container">
              <img src={ShoppingCart} alt="cart" className="cart-icon" />
              <span className="cart-text">
                {" "}
                <span className="cart-quantity">{totalQuantity}</span>
              </span>
            </div>
          </Link>

          <Link to="/download" className="topButton" onClick={removeNavLinksActive}>
            Download App
          </Link>
        </div>

        {/* Mobile Menu Icon */}
        <div className={`menu ${menuOpen ? "menu-active" : ""}`} id="hb" onClick={toggleMenu}>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </nav>
      <div className={`productsDropdownMenu ${dropdownOpen ? "productsDropdownMenu-active" : ""}`}>

        <div className="productsDropdownMenuPic"></div>

          <div className="productsDropdownMenuContent">
            <h3>Vehicle Care</h3>
            <div className="line"></div>
            <div className="productsDropdownMenuItems">

            {
                vehicleProducts.map((product) => (
                  <a href={`/product/${product.id}`}>
                    <img src={product.mainImage} alt="product" />
                    <span>{product.name}</span>
                  </a>
                ))
              }

            </div>
          </div>

          <div className="productsDropdownMenuContent">
            <h3>Kids Care</h3>
            <div className="line"></div>
            <div className="productsDropdownMenuItems">

            {
                kidsProducts.map((product) => (
                  <a href={`/product/${product.id}`}>
                    <img src={product.mainImage} alt="product" />
                    <span>{product.name}</span>
                  </a>
                ))
              }

            </div>
          </div>

          <div className="productsDropdownMenuContent">
            <h3>Personal Care</h3>
            <div className="line"></div>
            <div className="productsDropdownMenuItems">
              {
                personalproducts.map((product) => (
                  <a href={`/product/${product.id}`}>
                    <img src={product.mainImage} alt="product" />
                    <span>{product.name}</span>
                  </a>
                ))
              }

            </div>
          </div>

          <div className="productsDropdownMenuContent">
            <h3>Pet Care</h3>
            <div className="line"></div>
            <div className="productsDropdownMenuItems">

            {
                petProducts.map((product) => (
                  <a href={`/product/${product.id}`}>
                    <img src={product.mainImage} alt="product" />
                    <span>{product.name}</span>
                  </a>
                ))
              }

            </div>
          </div>





      </div>
    </header>
  );
};

export default Navbar;

// import React, { useState, useEffect } from 'react';
// import './Navbar.scss';  // Link to your updated CSS file
// import TrakidooLogo from '../../Assets/TrakidooLogo.png';
// import ShoppingCart from '../../Assets/Shoppin-cart.png';
// import profile from '../../Assets/profile-user.png';

// const Navbar = () => {
//   const [menuOpen, setMenuOpen] = useState(false);
//   const [scrolling, setScrolling] = useState(false);

//   const toggleMenu = () => {
//     setMenuOpen(!menuOpen);
//   };

//   const removeNavLinksActive = () => {
//     setMenuOpen(false);
//   };

//   useEffect(() => {
//     const handleScroll = () => {
//       if (window.scrollY > 50) {
//         setScrolling(true);
//       } else {
//         setScrolling(false);
//       }
//     };

//     window.addEventListener('scroll', handleScroll);
//     return () => window.removeEventListener('scroll', handleScroll);
//   }, []);

//   return (
//     <header className='navBox'>
//       <nav className={`nav ${scrolling ? 'nav-scrolled' : ''}`} id="nav">
//         {/* Logo */}
//         <div className="navLogoBox">
//           <a className="navLogo" href="/">
//             <img src={TrakidooLogo} alt="logo" />
//           </a>
//         </div>

//         {/* Navigation Links */}
//         <div className={`nav-links ${menuOpen ? 'nav-links-active' : ''}`}>
//           <ul>
//             <li><a href="/" onClick={removeNavLinksActive}>Home</a></li>
//             <li><a href="about" onClick={removeNavLinksActive}>About Us</a></li>
//             <li><a href="products" onClick={removeNavLinksActive}>Products</a></li>
//             <li><a href="features" onClick={removeNavLinksActive}>Features</a></li>
//             <li><a href="blogs" onClick={removeNavLinksActive}>Blogs</a></li>
//             <li><a href="#contact" onClick={removeNavLinksActive}>Contact Us</a></li>
//           </ul>
//         </div>

//         {/* Buttons and Icons */}
//         <div className="button-icons">
//           <a href="#"><img src={profile} alt="user" /></a>
//           <a href="cart"><img src={ShoppingCart} alt="cart" /></a>
//           <a href="download" className="topButton">Download App</a>
//         </div>

//         {/* Mobile Menu Icon */}
//         <div className="menu" id="hb" onClick={toggleMenu}>
//           <div></div>
//           <div></div>
//           <div></div>
//         </div>
//       </nav>
//     </header>
//   );
// };

// export default Navbar;
