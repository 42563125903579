import React, { useEffect, useState } from 'react'
import styles from './Vehicle.module.scss';
import './Vehicle.scss';
import videoSource from '../../Assets/vehicleVideo.mp4'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../firebase'; // Make sure to import your Firebase configuration
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

export default function Vehicle() {

  var [products, setProducts] = useState([]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const productsCollection = collection(db, 'products');
        const productsSnapshot = await getDocs(productsCollection);
        const productsList = productsSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
        setProducts(productsList);  // Save products to state
        filterProductsByType('vehicle', productsList); // Set initial filter to 'watch'

      } catch (error) {
        console.error('Error fetching products: ', error);
      }
    };

    fetchProducts();
  }, []);


  const filterProductsByType = (type, productList) => {
    const filtered = productList.filter(product => product.productType === type);
    setProducts(filtered);
  };

  return (
    <>
{/* <img className={styles.videoObject1} src={`${require("../../Assets/videoobject1.png")}`} alt="" /> */}
{/* <img className={styles.videoObject2} src={`${require("../../Assets/videoobject2.png")}`} alt="" /> */}
    
    <div className={styles.heroContainer}>

      {/* Video Background */}
      <video autoPlay loop muted className={styles.videoBackground}>
        <source src={videoSource} type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      {/* Hero Content */}
      <div className={styles.heroContent}>
        <h1>Track, Trace,    
       <br />  and Protect  </h1>
        <a href="#" className={styles.exploreButton}>Explore Products</a>
      </div>
    </div>


    <section className="PetSliderContainer">
        {/* <img className='PetOnSide' src={`${require("../../Assets/kids.png")}`} alt="" /> */}
        <Swiper className="PetSwiper"
          spaceBetween={0}
        //   loop={true}
          slidesPerView={1}
          autoplay={{
            delay: 2500,
            disableOnInteraction: true,
          }}
          centeredSlides={true}
          onSlideChange={() => console.log("slide change")}
          onSwiper={(swiper) => console.log(swiper)}
          grabCursor={true}
          modules={[Autoplay, Pagination, Navigation]}
          breakpoints={{
            // When window width is >= 320px
            320: {
              slidesPerView: 1,  // 1 slide for mobile devices
              centeredSlides: false,
            },
            // When window width is >= 640px
            640: {
              slidesPerView: 2,  // 2 slides for small tablets
              centeredSlides: false,
            },
            // When window width is >= 1024px
            1024: {
              slidesPerView: 3,  // 3 slides for desktops
              centeredSlides: true,
            },
          }}
        >

          {products.map((product) => (
            <SwiperSlide className="PetSwiperSlide" key={product.id}>
              <a href={`/product/${product.id}`}>
              <div className="PetSwiperImage">
                <img src={product.mainImage} alt="" />
              </div>
              <div className="PetSwiperText">
                <span>
                  <b>{product.name}</b>
                  <br />
                  <span>{product.tagline}</span>
                </span>
              </div>
              </a>
            </SwiperSlide>
          ))}

        </Swiper>

      </section>



      <section className={styles.PetMessageSection} style={{backgroundColor: "#25848C"}}>

            <div className={styles.PetMessageSectionImg}>

                <div className={styles.PetMessageSectionCircle} style={{backgroundImage: `url(${require("../../Assets/vehicle/1.jpg")})`}}></div>
            
            </div>

            <div className={styles.PetMessageSectionContent}>
                <div className={styles.PetMessageSectionText}>
                    <h1>Know when your car is going rough</h1>
                    <span>At Trackidoo, we provide a cutting-edge car-tracking device that monitors your vehicle's health in real-time. With Trackidoo, you can stay informed about your car's condition and receive immediate alerts for potential issues. By detecting problems early, you can avoid costly repairs and ensure a smooth and safe driving experience. </span>
                </div>
            </div>

        </section>


        <section className={styles.PetMessageSection} style={{marginTop: "200px"}}>

            <div className={styles.PetMessageSectionContent}>
                <div className={styles.PetMessageSectionText}>
                    <h1>Guarantee your vehicle's safety from theft</h1>
                    <span>Trackidoo is more than just a car tracking device; it's your vehicle's ultimate security solution. With Trackidoo, you can rest assured that your car is protected from theft. Our advanced tracking technology allows you to monitor your vehicle's location in real-time, making it easy to identify any unauthorised activity.</span>
                </div>
            </div>

            <div className={styles.PetMessageSectionImg}>

                <div className={styles.PetMessageSectionCircle} style={{backgroundImage: `url(${require("../../Assets/vehicle/2.jpg")})`}}></div>
            
            </div>

        </section>


        <Swiper style={{ marginTop: "100px" }}
        spaceBetween={0}
        //   loop={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: true,
        }}
        modules={[Autoplay, Pagination, Navigation]}
        slidesPerView={1}
        onSlideChange={() => console.log("slide change")}
        onSwiper={(swiper) => console.log(swiper)}
        grabCursor={true}
      >
        <SwiperSlide>
          <section className={styles.textSection}>
            <h1>
            "I recently had my car stolen, but thanks to the vehicle tracking device, we were able to locate it and recover it quickly. The immobilization feature was crucial in preventing further damage. I'm so grateful for this investment."              </h1>
              <div className={styles.textBallsSection}>
              <div className={styles.Ball}></div>
              <div className={styles.Ball}></div>
              <div className={styles.Ball}></div>
            </div>
          </section>
        </SwiperSlide>

        <SwiperSlide>
          <section className={styles.textSection}>
            <h1>
            "The Bluetooth light sensor is a great feature that has saved me from getting into a few accidents. Having my lights automatically turn on when I approach or leave my car is so convenient."                        
            </h1>
            <div className={styles.textBallsSection}>
              <div className={styles.Ball}></div>
              <div className={styles.Ball}></div>
              <div className={styles.Ball}></div>
            </div>
          </section>
        </SwiperSlide>

        <SwiperSlide>
          <section className={styles.textSection}>
            <h1>
            "I love that I can access my vehicle's data logs directly on the device. It's a great way to track my mileage, fuel consumption, and other important information."            </h1>
            <div className={styles.textBallsSection}>
              <div className={styles.Ball}></div>
              <div className={styles.Ball}></div>
              <div className={styles.Ball}></div>
            </div>
          </section>
        </SwiperSlide>


        <SwiperSlide>
          <section className={styles.textSection}>
            <h1>
            "The vehicle tracking device has been a lifesaver for my business. It helps me keep track of my fleet and ensure that they're being used efficiently. The immobilisation feature is also a great deterrent for theft."
            </h1>
            <div className={styles.textBallsSection}>
              <div className={styles.Ball}></div>
              <div className={styles.Ball}></div>
              <div className={styles.Ball}></div>
            </div>
          </section>
        </SwiperSlide>

      </Swiper>


    </>
  )
}
