import React, { useEffect, useState } from 'react'
import styles from './Kids.module.scss';
import './Kids.scss';
import videoSource from '../../Assets/kidsVideo-1.mp4'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../firebase'; // Make sure to import your Firebase configuration
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { Link, Navigate } from 'react-router-dom';

export default function Kids() {

  var [products, setProducts] = useState([]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const productsCollection = collection(db, 'products');
        const productsSnapshot = await getDocs(productsCollection);
        const productsList = productsSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
        setProducts(productsList);  // Save products to state
        filterProductsByType('kids', productsList); // Set initial filter to 'watch'

      } catch (error) {
        console.error('Error fetching products: ', error);
      }
    };

    fetchProducts();
  }, []);

  const handleProductClick = (productId) => {
    // console.log("Navigating to product with ID:", productId);  
    Navigate(`/product/${productId}`);  // Navigate to the product page with the productId
  };


  const filterProductsByType = (type, productList) => {
    const filtered = productList.filter(product => product.productType === type);
    setProducts(filtered);
  };

  return (
    <>

<img className={styles.videoObject1} src={`${require("../../Assets/videoobject1.png")}`} alt="" />
<img className={styles.videoObject2} src={`${require("../../Assets/videoobject2.png")}`} alt="" />
    
    <div className={styles.heroContainer}>

      {/* Video Background */}
      <video autoPlay loop muted className={styles.videoBackground}>
        <source src={videoSource} type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      {/* Hero Content */}
      <div className={styles.heroContent}>
        <h1>Your Child.  
       <br />  Your Safety. </h1>
        <a href="#" className={styles.exploreButton}>Explore Products</a>
      </div>
    </div>


    <section className="kidsSliderContainer">
        <img className='kidsOnSide' src={`${require("../../Assets/kids.png")}`} alt="" />
        <Swiper className="kidsSwiper"
          spaceBetween={0}
        //   loop={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: true,
        }}
          slidesPerView={1}
          centeredSlides={true}
          onSlideChange={() => console.log("slide change")}
          onSwiper={(swiper) => console.log(swiper)}
          grabCursor={true}
          modules={[Autoplay, Pagination, Navigation]}
          breakpoints={{
            // When window width is >= 320px
            320: {
              slidesPerView: 1,  // 1 slide for mobile devices
              centeredSlides: false,
            },
            // When window width is >= 640px
            640: {
              slidesPerView: 2,  // 2 slides for small tablets
              centeredSlides: false,
            },
            // When window width is >= 1024px
            1024: {
              slidesPerView: 3,  // 3 slides for desktops
              centeredSlides: true,
            },
          }}
        >
          {
            products.map((product) => (
              <SwiperSlide className="kidsSwiperSlide" key={product.id}>
                <div className="kidsSwiperImage" onClick={() => handleProductClick(product.id)}>
                    <img src={product.mainImage} alt="" />
                </div>
                <div className="kidsSwiperText">
                    <span>
                        <b>{product.name}</b>
                        <br />
                        <span>{product.tagline}</span>
                    </span>
                </div>
            </SwiperSlide>
            ))
          }
           
        </Swiper>

      </section>



      <section className={styles.kidsMessageSection}>

            <div className={styles.kidsMessageSectionImg}>

                <img src={require("../../Assets/kids/rectangle23.png")} alt="" />
            
            </div>

            <div className={styles.kidsMessageSectionContent}>
                <div className={styles.kidsMessageSectionText}>
                    <h1>Pay attention to your child's surroundings.</h1>
                    <span>Our advanced childcare devices offer cutting-edge features designed to protect and monitor your little ones, wherever they may be. With Trackidoo, you can stay connected to your child's location, receive real-time alerts, and ensure their safety and well-being. Experience the peace of mind of knowing your child is always safe and protected with Trackidoo.</span>
                </div>
            </div>

        </section>

        <section className={styles.kidsMessageSection}>

            <div className={styles.kidsMessageSectionContent}>
                <div className={styles.kidsMessageSectionText}>
                    <h1>Know exactly where your child is right now</h1>
                    <span>Always know your child's location with real-time GPS tracking. Our advanced technology provides precise updates, ensuring your peace of mind. With Trackidoo, you can stay connected to your child's whereabouts wherever they go. Have peace of mind with real-time location tracking. Never lose sight of your child, even from afar.</span>
                </div>
            </div>

            <div className={styles.kidsMessageSectionImg}>

                <img src={require("../../Assets/kids/rectangle22.png")} alt="" />
            
            </div>

        </section>


        <Swiper style={{ marginTop: "100px" }}
        spaceBetween={0}
        //   loop={true}
        slidesPerView={1}
        autoplay={{
          delay: 2500,
          disableOnInteraction: true,
        }}
        modules={[Autoplay, Pagination, Navigation]}
        onSlideChange={() => console.log("slide change")}
        onSwiper={(swiper) => console.log(swiper)}
        grabCursor={true}
      >
        <SwiperSlide>
          <section className={styles.textSection}>
            <h1>
            "Findzy has been a lifesaver for my family. As a busy parent, it's incredibly reassuring to know that I can always keep track of my child's location. The real-time tracking and two-way communication features have given me so much peace of mind. I highly recommend Findzy to any parent who wants to ensure their child's safety."            
              </h1>
              <div className={styles.textBallsSection}>
              <div className={styles.Ball}></div>
              <div className={styles.Ball}></div>
              <div className={styles.Ball}></div>
            </div>
          </section>
        </SwiperSlide>

        <SwiperSlide>
          <section className={styles.textSection}>
            <h1>
            "I was initially hesitant about using a tracking device for my child, but Findzy has completely changed my mind. The voice monitoring feature is incredibly helpful, and I love that I can talk to my child directly through the device. The long battery life and easy-to-use interface make it a perfect fit for our family."</h1>
                        <div className={styles.textBallsSection}>
              <div className={styles.Ball}></div>
              <div className={styles.Ball}></div>
              <div className={styles.Ball}></div>
            </div>
          </section>
        </SwiperSlide>

        <SwiperSlide>
          <section className={styles.textSection}>
            <h1>
            "Kiddoo has been a great help for our family vacations. We can always keep track of our child's location and make sure they're safe. The front camera is a great way to capture memories and stay connected, even when we're far apart. The SOS button and two-way communication features give us peace of mind knowing that we can always reach out to our child in case of an emergency."            </h1>
                        <div className={styles.textBallsSection}>
              <div className={styles.Ball}></div>
              <div className={styles.Ball}></div>
              <div className={styles.Ball}></div>
            </div>
          </section>
        </SwiperSlide>

        <SwiperSlide>
          <section className={styles.textSection}>
            <h1>
            "Kiddoo has been a great investment for our family. It's given us peace of mind and has helped us to keep our children safe. I highly recommend it to any parent who wants to ensure their child's safety and well-being."            </h1>
            <div className={styles.textBallsSection}>
              <div className={styles.Ball}></div>
              <div className={styles.Ball}></div>
              <div className={styles.Ball}></div>
            </div>
          </section>
        </SwiperSlide>

      </Swiper>

    </>
  )
}
