import axios from 'axios';





 
// Function to create an order using the Firebase Cloud Function for Shiprocket
export const createShiprocketOrder = async (orderDetails) => {
  try {
    // Call the Firebase Cloud Function to create a Shiprocket order
    const response = await axios.post(
      'https://us-central1-gpsdesk-5171c.cloudfunctions.net/createShiprocketOrder', 
      orderDetails
    );
    return response.data; // Return the response data
  } catch (error) {
    console.error('Error creating Shiprocket order via Firebase Cloud Function:', error);
    throw new Error('Failed to create Shiprocket order');
  }
};





// Function to fetch order status from Firebase Cloud Function
export const getShiprocketOrderStatus = async (orderId) => {
  try {
    // Make a GET request to the Firebase function that fetches the Shiprocket order status
    const response = await axios.get(
      'https://us-central1-gpsdesk-5171c.cloudfunctions.net/getShiprocketOrderStatus', // Replace with the actual Firebase URL
      {
        params: {
          orderId: orderId, // Pass the orderId as a query parameter
        },
      }
    );
    return response.data; // Return the response data with order status
  } catch (error) {
    console.error('Error fetching Shiprocket order status from Firebase function:', error);
    return null;
  }
};

