import React, { useEffect, useState } from "react";
import styles from "./Pet.module.scss";
import "./Pet.scss";
import videoSource from "../../Assets/petvideo.mp4";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase"; // Make sure to import your Firebase configuration
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

export default function Pets() {
  var [products, setProducts] = useState([]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const productsCollection = collection(db, "products");
        const productsSnapshot = await getDocs(productsCollection);
        const productsList = productsSnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        setProducts(productsList); // Save products to state
        filterProductsByType("pet", productsList); // Set initial filter to 'watch'
      } catch (error) {
        console.error("Error fetching products: ", error);
      }
    };

    fetchProducts();
  }, []);

  const filterProductsByType = (type, productList) => {
    const filtered = productList.filter(
      (product) => product.productType === type
    );
    setProducts(filtered);
  };

  return (
    <>
      <img
        className={styles.videoObject1}
        src={`${require("../../Assets/videoobject1.png")}`}
        alt=""
      />
      <img
        className={styles.videoObject2}
        src={`${require("../../Assets/videoobject2.png")}`}
        alt=""
      />

      <div className={styles.heroContainer}>
        {/* Video Background */}
        <video autoPlay loop muted className={styles.videoBackground}>
          <source src={videoSource} type="video/mp4" />
          Your browser does not support the video tag.
        </video>

        {/* Hero Content */}
        <div className={styles.heroContent}>
          <h1>
            Protect Your Pet,
            <br /> Track Their Location{" "}
          </h1>
          <a href="#" className={styles.exploreButton}>
            Explore Products
          </a>
        </div>
      </div>

      <section className="PetSliderContainer">
        {/* <img className='PetOnSide' src={`${require("../../Assets/kids.png")}`} alt="" /> */}
        <Swiper
          className="PetSwiper"
          spaceBetween={0}
          //   loop={true}
          slidesPerView={1}
          autoplay={{
            delay: 2500,
            disableOnInteraction: true,
          }}
          centeredSlides={true}
          onSlideChange={() => console.log("slide change")}
          onSwiper={(swiper) => console.log(swiper)}
          modules={[Autoplay, Pagination, Navigation]}
          grabCursor={true}
          breakpoints={{
            // When window width is >= 320px
            320: {
              slidesPerView: 1, // 1 slide for mobile devices
              centeredSlides: false,
            },
            // When window width is >= 640px
            640: {
              slidesPerView: 2, // 2 slides for small tablets
              centeredSlides: false,
            },
            // When window width is >= 1024px
            1024: {
              slidesPerView: 3, // 3 slides for desktops
              centeredSlides: true,
            },
          }}
        >
          {products.map((product) => (
            <SwiperSlide className="PetSwiperSlide" key={product.id}>
              <a href={`product/${product.id}`}>
                <div className="PetSwiperImage">
                  <img src={product.mainImage} alt="" />
                </div>
                <div className="PetSwiperText">
                  <span>
                    <b>{product.productName}</b>
                    <br />
                    <span>{product.tagline}</span>
                  </span>
                </div>
              </a>
            </SwiperSlide>
          ))}
        </Swiper>
      </section>

      <section className={styles.PetMessageSection}>
        <img
          className={styles.dogPic}
          src={require("../../Assets/dog.png")}
          alt=""
        />

        <div className={styles.PetMessageSectionImg}>
          <div
            className={styles.PetMessageSectionCircle}
            style={{
              backgroundImage: `url(${require("../../Assets/pet/1.jpg")})`,
            }}
          ></div>
        </div>

        <div className={styles.PetMessageSectionContent}>
          <div className={styles.PetMessageSectionText}>
            <h1>Peace of mind for pet owners</h1>
            <span>
              More than just a pet tracker. Our advanced technology provides
              real-time location updates, geofencing capabilities, activity
              monitoring, and lost pet recovery assistance. With PawSafe, you
              can enjoy the peace of mind of knowing your pet is always safe and
              secure, no matter where their adventures take them.
            </span>
          </div>
        </div>
      </section>

      <section
        className={styles.PetMessageSection}
        style={{ marginTop: "200px" }}
      >
        <img
          className={styles.pawPic}
          src={require("../../Assets/paw.png")}
          alt=""
        />

        <div className={styles.PetMessageSectionContent}>
          <div className={styles.PetMessageSectionText}>
            <h1>Track your furry friend's every move</h1>
            <span>
              Never lose sight of your furry friend again. Our advanced pet
              tracking technology allows you to monitor your pet's every move,
              ensuring their safety and security. With PawSafe, you'll always
              know exactly where your pet is, giving you peace of mind and the
              ability to intervene if necessary.
            </span>
          </div>
        </div>

        <div className={styles.PetMessageSectionImg}>
          <div
            className={styles.PetMessageSectionCircle}
            style={{
              backgroundImage: `url(${require("../../Assets/pet/2.jpg")})`,
            }}
          ></div>
        </div>
      </section>

      <Swiper style={{ marginTop: "100px" }}
        spaceBetween={0}
        //   loop={true}
          modules={[Autoplay, Pagination, Navigation]}
          slidesPerView={1}
          autoplay={{
            delay: 2500,
            disableOnInteraction: true,
          }}
        onSlideChange={() => console.log("slide change")}
        onSwiper={(swiper) => console.log(swiper)}
        grabCursor={true}
      >
        <SwiperSlide>
          <section className={styles.textSection}>
            <h1>
            "I can't imagine my life without PawSafe. As a busy pet owner, it's incredibly reassuring to know that I can always keep track of my dog's whereabouts. The real-time location updates and geofencing features have given me much peace of mind. When my dog went missing last year, PawSafe helped me find him quickly and safely. I'm so grateful for this amazing product!"
            </h1>
            <div className={styles.textBallsSection}>
              <div className={styles.Ball}></div>
              <div className={styles.Ball}></div>
              <div className={styles.Ball}></div>
            </div>
          </section>
        </SwiperSlide>

        <SwiperSlide>
          <section className={styles.textSection}>
            <h1>
            "PawSafe has been a lifesaver for our family. Our cat loves to explore the neighbourhood, and I was always worried about her safety. With PawSafe, I can track her location and make sure she's always safe. It's given me so much peace of mind.""
            </h1>
            <div className={styles.textBallsSection}>
              <div className={styles.Ball}></div>
              <div className={styles.Ball}></div>
              <div className={styles.Ball}></div>
            </div>
          </section>
        </SwiperSlide>

        <SwiperSlide>
          <section className={styles.textSection}>
            <h1>
              "I recently moved to a new city with my dog. PawSafe helped me get to know the neighbourhood and set up safe zones for her. It's been a great tool for keeping her safe and exploring new places."
            </h1>
            <div className={styles.textBallsSection}>
              <div className={styles.Ball}></div>
              <div className={styles.Ball}></div>
              <div className={styles.Ball}></div>
            </div>
          </section>
        </SwiperSlide>

        <SwiperSlide>
          <section className={styles.textSection}>
            <h1>
            "Our dog loves to go hiking with us, but we were always worried about her getting lost in the woods. PawSafe has been a game-changer. We can track her location and make sure she's always nearby."            </h1>
            <div className={styles.textBallsSection}>
              <div className={styles.Ball}></div>
              <div className={styles.Ball}></div>
              <div className={styles.Ball}></div>
            </div>
          </section>
        </SwiperSlide>


      </Swiper>
    </>
  );
}
