import React from 'react';
import HeroSection from "../../components/HeroSection/HeroSection";
import SectionContent from "../../components/SectionContent/SectionContent";
import MobileSection from "../../components/MobileSection/MobileSection"
import CardSection from "../../components/CardSection/CardSection";
import WhyChooseUs from '../../components/WhyChoseUs/WhyChooseUs';
import OurProducts from '../../components/OurProducts/OurProducts';
import GifSection from '../../components/GifSection/GifSection';
import SliderSection from '../../components/SliderSection/SliderSection';
import MessagesSection from '../../components/MessagesSection/MessagesSection';
import Testimonials from '../../components/Testimonials/Testimonials';

const Home = () => {
  return (
    <>
     <HeroSection/>
     <SliderSection/>
     <MessagesSection/>
     {/* <SectionContent/> */}
     <MobileSection/>
     {/* <WhyChooseUs/> */}
     {/* <CardSection/> */}
     {/* <OurProducts/> */}
     <GifSection/>
     <Testimonials/>

    </>
  );
};

export default Home;