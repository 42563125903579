import React from "react";
import styles from "./Testimonials.module.scss";

export default function Testimonials() {
  return (
    <>
      <section className={styles.testimonials}>
        <h1>What our customer says</h1>

        <div className={styles.testimonialsContainer}>
          <div className={styles.testimonial}>
            <div
              className={styles.testimonialImg}
              style={{
                backgroundImage: `url(${require(`../../Assets/testimonials/new3.jpg`)})`,
              }}
            ></div>

            <div className={styles.testimonialContent}>
              <h2>Harsh Tanveer</h2>
              <a className="colouredButton" href="">
                Roadee IOT Device
              </a>
              <span>
                "Roadee has been a game-changer for my family's safety. The
                real-time tracking and driving behavior monitoring give me peace
                of mind, especially with my teenager behind the wheel. The
                remote power cut-off is a fantastic feature too—it's like having
                an extra layer of security. Highly recommended for anyone who
                values safety on the road!"
              </span>
            </div>
          </div>

          <div className={styles.testimonial}>
            <div
              className={styles.testimonialImg}
              style={{
                backgroundImage: `url(${require(`../../Assets/testimonials/new2.jpg`)})`,
              }}
            ></div>

            <div className={styles.testimonialContent}>
              <h2>Siddharth Sharma</h2>
              <a className="colouredButton" href="">
                Kidoo Smart Watch
              </a>
              <span>
                "Kiddoo has been a lifesaver! My daughter feels independent with
                her watch, and I feel at ease knowing I can track her location
                in real time. The SOS button gives me added comfort, and the
                front camera allows her to share her adventures with us. Plus,
                the battery life is incredible! I wouldn't trust any other
                device for my child's safety."
              </span>
            </div>
          </div>

          <div className={styles.testimonial}>
            <div
              className={styles.testimonialImg}
              style={{
                backgroundImage: `url(${require(`../../Assets//testimonials/new1.jpg`)})`,
              }}
            ></div>

            <div className={styles.testimonialContent}>
              <h2>Neha Srivastav</h2>
              <a className="colouredButton" href="">
                Seekr Personal GPS Tracker
              </a>
              <span>
                "I can't believe how much easier Seekr has made my life. I often
                travel for work, and knowing I can track my luggage and
                important items through the Findr app is a relief. The community
                finding feature even helped me recover a misplaced item! Simple,
                reliable, and worth every penny."
              </span>
            </div>
          </div>
        </div>
      </section>

      <section className={styles.subscribe}>
        <div className={styles.subscribeContent}>
          <span>
            Sign up to receive exclusive news and offers about the latest
            launches
          </span>
          <div className={styles.subscribeButton}>
            <input type="text" placeholder="Enter your email" />
            <button>SUBSCRIBE</button>
          </div>
        </div>
      </section>
    </>
  );
}
