import React, { useEffect, useState } from 'react'
import styles from './PersonalTracker.module.scss';
import './PersonalTracker.scss';
import videoSource from '../../Assets/PersonalTrackerVideo.webm';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../firebase'; // Make sure to import your Firebase configuration
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

export default function PersonalTracker() {

  var [products, setProducts] = useState([]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const productsCollection = collection(db, 'products');
        const productsSnapshot = await getDocs(productsCollection);
        const productsList = productsSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
        setProducts(productsList);  // Save products to state
        filterProductsByType('personaltracker', productsList); // Set initial filter to 'watch'

      } catch (error) {
        console.error('Error fetching products: ', error);
      }
    };

    fetchProducts();
  }, []);


  const filterProductsByType = (type, productList) => {
    const filtered = productList.filter(product => product.productType === type);
    setProducts(filtered);
  };

  return (
    <>

{/* <img className={styles.videoObject1} src={`${require("../../Assets/videoobject1.png")}`} alt="" /> */}
{/* <img className={styles.videoObject2} src={`${require("../../Assets/videoobject2.png")}`} alt="" /> */}
    
    <div className={styles.heroContainer}>

      {/* Video Background */}
      <video autoPlay loop muted className={styles.videoBackground}>
        <source src={videoSource} type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      {/* Hero Content */}
      <div className={styles.heroContent}>
        <h1>Locate Your   
       <br />  Valuables  </h1>
        <a href="#" className={styles.exploreButton}>Explore Products</a>
      </div>
    </div>


    <section className="PetSliderContainer">
        {/* <img className='PetOnSide' src={`${require("../../Assets/kids.png")}`} alt="" /> */}
        <Swiper className="PetSwiper"
          spaceBetween={0}
        //   loop={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: true,
        }}
          slidesPerView={1}
          centeredSlides={true}
          onSlideChange={() => console.log("slide change")}
          onSwiper={(swiper) => console.log(swiper)}
          modules={[Autoplay, Pagination, Navigation]}
          grabCursor={true}
          breakpoints={{
            // When window width is >= 320px
            320: {
              slidesPerView: 1,  // 1 slide for mobile devices
              centeredSlides: false,
            },
            // When window width is >= 640px
            640: {
              slidesPerView: 2,  // 2 slides for small tablets
              centeredSlides: false,
            },
            // When window width is >= 1024px
            1024: {
              slidesPerView: 3,  // 3 slides for desktops
              centeredSlides: true,
            },
          }}
        >

          {
            products.map((product) => (
              <SwiperSlide key={product.id} className="PetSwiperSlide">
                <a href={`product/${product.id}`}>
                <div className="PetSwiperImage">
                  <img src={product.mainImage} alt="" />
                </div>
                <div className="PetSwiperText">
                  <span>
                    <b>{product.name}</b>
                    <br />
                    <span>{product.tagline}</span>
                  </span>
                </div>
              </a>
              </SwiperSlide>
            ))
          }


            
        </Swiper>

      </section>



      <section className={styles.PetMessageSection}>

            <div className={styles.PetMessageSectionImg}>

                <div className={styles.PetMessageSectionCircle} style={{backgroundImage: `url(${require("../../Assets/PersonalTracker/1.jpg")})`}}></div>
            
            </div>

            <div className={styles.PetMessageSectionContent}>
                <div className={styles.PetMessageSectionText}>
                    <h1>A tiny treasure, tremendous value</h1>
                    <span>This compact and discreet device is a valuable tool that can save you time and stress. Despite its small size, findzy is a mighty helper that can locate your most precious belongings with ease. With findzy, you'll never have to worry about losing your keys, wallet, or other important items again.</span>
                </div>
            </div>

        </section>

        <section className={styles.PetMessageSection}>

           
            <div className={styles.PetMessageSectionContent}>
                <div className={styles.PetMessageSectionText}>
                    <h1>Perfect for the on-the-go lifestyle</h1>
                    <span>This compact and portable device is the ideal companion for your busy, mobile life. Keep track of your belongings, no matter where your adventures take you. With findzy, you can stay organised and efficient, even when you're always on the move—the ultimate accessory for your fast-paced lifestyle.</span>
                </div>
            </div>


            <div className={styles.PetMessageSectionImg}>

                <div className={styles.PetMessageSectionCircle2} style={{backgroundImage: `url(${require("../../Assets/PersonalTracker/2.jpg")})`}}></div>
            
            </div>

        </section>

        


        <Swiper style={{ marginTop: "100px" }}
        spaceBetween={0}
        autoplay={{
          delay: 2500,
          disableOnInteraction: true,
        }}
        //   loop={true}
          modules={[Autoplay, Pagination, Navigation]}
          slidesPerView={1}
        onSlideChange={() => console.log("slide change")}
        onSwiper={(swiper) => console.log(swiper)}
        grabCursor={true}
      >
        <SwiperSlide>
          <section className={styles.textSection}>
            <h1>
            "Seekr has been an absolute lifesaver! I'm always misplacing my keys or wallet, but now I can track their location in real-time using the app. The long battery life means I don't have to worry about running out of power, and the loud alarm is incredibly helpful when I'm searching for something nearby. I highly recommend Seekr to anyone who struggles with losing their belongings."            </h1>
            <div className={styles.textBallsSection}>
              <div className={styles.Ball}></div>
              <div className={styles.Ball}></div>
              <div className={styles.Ball}></div>
            </div>
          </section>
        </SwiperSlide>

        <SwiperSlide>
          <section className={styles.textSection}>
            <h1>
            "Seekr has been a game-changer for my forgetful self! I used to misplace my keys, wallet, and even my phone on a regular basis. But now, with Seekr, I can always find them in a matter of seconds. The real-time tracking and loud alarm have been incredibly helpful. I love that I can track my belongings from anywhere, and the long battery life means I don't have to worry about charging it constantly. Seekr is a must-have for anyone who struggles with losing things."            </h1>
            <div className={styles.textBallsSection}>
              <div className={styles.Ball}></div>
              <div className={styles.Ball}></div>
              <div className={styles.Ball}></div>
            </div>
          </section>
        </SwiperSlide>

      </Swiper>



    </>
  )
}
