// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { getFirestore, doc, setDoc } from "firebase/firestore";
import { getStorage } from "firebase/storage";


// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAXAZmJGQiXZOrFJkybRrEKCTN5JV79Jcc",
  authDomain: "gpsdesk-5171c.firebaseapp.com",
  projectId: "gpsdesk-5171c",
  storageBucket: "gpsdesk-5171c.appspot.com",
  messagingSenderId: "168535133412",
  appId: "1:168535133412:web:68e28a9148c4c774db70f1",
  measurementId: "G-GJKKJPHD96"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Firebase services
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

// Google Auth Provider
const provider = new GoogleAuthProvider();

// Function to handle Google Sign-In and saving the user in Firestore


// Export the initialized Firebase app and services
export { auth, db, storage, provider };