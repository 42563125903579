import React, { useEffect, useState } from 'react';
import {
  collection,
  query,
  where,
  orderBy,
  limit,
  startAfter,
  startAt,
  getDocs,
} from 'firebase/firestore';
import { db } from '../../firebase'; // Firebase setup
import { useAuth } from '../../contexts/AuthContext'; // Hook for authentication
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Typography,
  CircularProgress,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
} from '@mui/material';
import { toast } from 'react-toastify';
import './orderDetail.scss';

export default function Orders() {
  const { currentUser } = useAuth(); // Get the current user's ID
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [lastDoc, setLastDoc] = useState(null);
  const [firstDocArr, setFirstDocArr] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10; // Number of orders per page
  const navigate = useNavigate();

  // Function to fetch orders with pagination
  const fetchOrders = async (direction = 'next', startPoint = null) => {
    setLoading(true);
    try {
      let ordersQuery = query(
        collection(db, 'orders'),
        where('customerId', '==', currentUser.uid),
        orderBy('createdAt', 'desc'),
        limit(pageSize)
      );

      if (direction === 'next' && startPoint) {
        ordersQuery = query(
          collection(db, 'orders'),
          where('customerId', '==', currentUser.uid),
          orderBy('createdAt', 'desc'),
          startAfter(startPoint),
          limit(pageSize)
        );
      } else if (direction === 'prev' && startPoint) {
        ordersQuery = query(
          collection(db, 'orders'),
          where('customerId', '==', currentUser.uid),
          orderBy('createdAt', 'desc'),
          startAt(startPoint),
          limit(pageSize)
        );
      }

      const ordersSnapshot = await getDocs(ordersQuery);
      const ordersList = ordersSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      // Update pagination state
      if (direction === 'next') {
        setLastDoc(ordersSnapshot.docs[ordersSnapshot.docs.length - 1]);
        setFirstDocArr((prev) => [...prev, ordersSnapshot.docs[0]]);
        setCurrentPage((prev) => prev + 1);
      } else if (direction === 'prev') {
        setFirstDocArr((prev) => prev.slice(0, -1));
        setCurrentPage((prev) => prev - 1);
        setLastDoc(ordersSnapshot.docs[ordersSnapshot.docs.length - 1]);
      } else {
        // Reset pagination state when fetching the first page
        setFirstDocArr([]);
        setCurrentPage(1);
        if (ordersSnapshot.docs.length > 0) {
          setLastDoc(ordersSnapshot.docs[ordersSnapshot.docs.length - 1]);
          setFirstDocArr([ordersSnapshot.docs[0]]);
        } else {
          setLastDoc(null);
        }
      }

      setOrders(ordersList);
    } catch (error) {
      console.error('Error fetching orders:', error);
      toast.error('Failed to fetch orders.');
    } finally {
      setLoading(false);
    }
  };

  // Fetch initial orders
  useEffect(() => {
    if (currentUser) {
      fetchOrders();
    }
  }, [currentUser]);

  // Handle next page
  const handleNextPage = () => {
    if (lastDoc) {
      fetchOrders('next', lastDoc);
    }
  };

  // Handle previous page
  const handlePrevPage = () => {
    if (currentPage > 1 && firstDocArr[currentPage - 2]) {
      fetchOrders('prev', firstDocArr[currentPage - 2]);
    } else if (currentPage === 1) {
      fetchOrders();
    }
  };

  // Handle order click
  const handleOrderClick = (orderId) => {
    navigate(`/order/${orderId}`);
  };

  return (
    <div className="orderDetailContainer">
      <Typography variant="h4" align="center" gutterBottom>
        Your Orders
      </Typography>

      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="100px">
          <CircularProgress />
        </Box>
      ) : orders.length === 0 ? (
        <Typography variant="h6" align="center">
          No orders found.
        </Typography>
      ) : (
        <>
          {/* Table Display */}
          <TableContainer component={Paper}>
            <Table aria-label="Orders table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">Order ID</TableCell>
                  <TableCell align="left">Order Status</TableCell>
                  <TableCell align="left">Total Price</TableCell>
                  <TableCell align="left">Transaction Time</TableCell>
                  <TableCell align="left">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orders.map((order) => (
                  <TableRow key={order.id}>
                    <TableCell>{order.id}</TableCell>
                    <TableCell>{order.orderStatus}</TableCell>
                    <TableCell>₹{order.totalPrice}</TableCell>
                    <TableCell>
                      {order.transactionTime
                        ? order.transactionTime.toDate().toLocaleString()
                        : 'N/A'}
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => handleOrderClick(order.id)}
                      >
                        View
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          {/* Pagination Buttons */}
          <Box display="flex" justifyContent="space-evenly" mt={2} mb={2}>
            <Button
              variant="contained"
              onClick={handlePrevPage}
              disabled={currentPage <= 1}
            >
              Previous
            </Button>
            <Button
              variant="contained"
              onClick={handleNextPage}
              disabled={orders.length < pageSize}
            >
              Next
            </Button>
          </Box>
        </>
      )}
    </div>
  );
}
