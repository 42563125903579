import React from 'react'
import './MessagesSection.scss'

export default function MessagesSection() {
  return (
    <>
    
        <section className='messageSection'>

            <div className='messageSectionImg' style={{backgroundImage: `url(${require(`../../Assets/Main/1.jpg`)})`}} ></div>

            <div className='messageSectionContent' style={{backgroundColor: '#FCB822'}}>
                <div className='messageSectionText'>
                    <h1>Lost Your Stuff One Too Many Times? findzy Is Here To Help You.</h1>
                    <span>Are your friends concerned you've gone broke because you keep ”losing your wallet”? findzy is a tiny guardian angel for your possessions, ensuring they're always within reach.</span>
                    <a className='colouredButton' href="personaltracker">Explore Offerings</a>
                </div>
            </div>

        </section>

        <section className='messageSection'>

            <div className='messageSectionContent' style={{backgroundColor: '#25848C'}}>
                <div className='messageSectionText'>
                    <h1>No more car theft nightmares.</h1>
                    <span>Is the fear of your dream car being stolen keeping you up at night? EasyGo is a protective tracker that watches over your vehicle with unwavering loyalty.</span>
                    <a className='colouredButton' href="vehicle">Explore Offerings</a>
                </div>
            </div>

            <div className='messageSectionImg' style={{backgroundImage: `url(${require(`../../Assets/Main/2.jpg`)})`}} ></div>

        </section>

        <section className='messageSection'>

            <div className='messageSectionImg' style={{backgroundImage: `url(${require(`../../Assets/Main/3.jpg`)})`}} ></div>
        
            <div className='messageSectionContent' style={{backgroundColor: '#76B6EE'}}>
                <div className='messageSectionText'>
                    <h1>Lost? No Way. Kiddoo Will Always Guide You.</h1>
                    <span>Nothing is more embarrassing than losing your kid. With Kiddoo you can keep tabs on your little one, so you'll always know if they're building a fort out of leaves or just eating dirt.</span>
                    <a className='colouredButton' href="kids">Explore Offerings</a>
                </div>
            </div>

        </section>

        <section className='messageSection'>

            <div className='messageSectionContent' style={{backgroundColor: '#FCB822'}}>
                <div className='messageSectionText'>
                    <h1>Keep Tabs on Your Tail-Wagger with PawSafe</h1>
                    <span>Ever feel like your pet might be a secret agent on a mission to stop an evil genius? Sure, your pet isn't exactly Perry the Platypus, but how else do you explain those long absences? With our Pawsafe, you'll always know where their secret missions take them.</span>
                    <a className='colouredButton' href="pet">Explore Offerings</a>
                </div>
            </div>

            <div className='messageSectionImg' style={{backgroundImage: `url(${require(`../../Assets/Main/4.jpg`)})`}} ></div>

        </section>


    </>
  )
}
