import React from "react";
import styles from "./HeroSection.module.scss"; // Using CSS Modules
// import videoSource from "../../Assets/TrackidooMainVideo.webm"; // Import the video from your assets
import personalTrackerVideo from "../../Assets/PersonalTrackerVideo.webm";
import vehicleVideo from "../../Assets/vehicleVideo.mp4";
import petVideo from "../../Assets/petvideo.mp4";
import kidsVideo from "../../Assets/kidsVideo-1.mp4";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
// import "swiper/swiper-bundle.min.css";
import "swiper/css";

const HeroSection = () => {
  return (
    <>
      <Swiper
        className="swiper"
        spaceBetween={0}
        autoplay={{
          delay: 2500,
          disableOnInteraction: true,
        }}
        slidesPerView={1}
        onSlideChange={() => console.log("slide change")}
        onSwiper={(swiper) => console.log(swiper)}
        grabCursor={true}
        modules={[Autoplay, Pagination, Navigation]}
      >
        <SwiperSlide>
          <div className={styles.heroContainer}>
            {/* Video Background */}
            <div className={styles.videoOut}>
              <video autoPlay loop muted className={styles.videoBackground}>
                <source src={personalTrackerVideo} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
            {/* Hero Content */}
            <div className={styles.heroContent}>
              <h1>
                Locate Your <br />
                Valuables
              </h1>
              <a href="personaltracker" className={styles.exploreButton}>
                Explore Products
              </a>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className={styles.heroContainer}>
            {/* Video Background */}
            <div className={styles.videoOut}>
              <video autoPlay loop muted className={styles.videoBackground}>
                <source src={vehicleVideo} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
            {/* Hero Content */}
            <div className={styles.heroContent}>
              <h1>
                Track, Trace, <br /> and Protect
              </h1>
              <a href="vehicle" className={styles.exploreButton}>
                Explore Products
              </a>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className={styles.heroContainer}>
            {/* Video Background */}
            <div className={styles.videoOut}>
              <video autoPlay loop muted className={styles.videoBackground}>
                <source src={kidsVideo} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
            {/* Hero Content */}
            <div className={styles.heroContent}>
              <h1>
                Your Child. <br />
                Your Safety.
              </h1>
              <a href="kids" className={styles.exploreButton}>
                Explore Products
              </a>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className={styles.heroContainer}>
            {/* Video Background */}
            <div className={styles.videoOut}>
              <video autoPlay loop muted className={styles.videoBackground}>
                <source src={petVideo} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
            {/* Hero Content */}
            <div className={styles.heroContent}>
              <h1>Protect Your Pet, <br /> Track Their Location</h1>
              <a href="pet" className={styles.exploreButton}>
                Explore Products
              </a>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </>
  );
};

export default HeroSection;
